<div class="row justify-content-md-center page">
  <div class="col-md-8">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="/assets/images/winter-logo.gif" alt="NightDesign Logo">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fa-solid fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse me-auto" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="btn btn-secondary" href="https://night.design/home">Home</a>
            </li>
            <li class="nav-item">
              <a class="btn btn-secondary" href="https://night.design/resources">Resources</a>
            </li>
            <li class="nav-item">
              <a class="btn btn-secondary" href="https://night.design/team">Team</a>
            </li>
            <li class="nav-item">
              <a class="btn btn-secondary" href="https://night.design/discord">Discord</a>
            </li>
            <li class="nav-item">
              <a class="btn btn-secondary active" href="/">GG-Prefix</a>
            </li>
          </ul>
        </div>

        <div class="d-flex">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="btn btn-secondary account" href="https://night.design/login">Login</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <div class="col-md-8">

    <div class="card">
      <div class="card-body">
        <ng-select [clearable]="false" class="select" groupBy="category" [(ngModel)]="selectedTemplate" (change)="onChange()">
          <ng-option *ngFor="let template of defaultTemplates" [value]="template">{{template.name}} - <span class="no-shadow" [innerHTML]="generateText('Beispieltext', template, true).html"></span></ng-option>

          <ng-template ng-optgroup-tmp let-item="item">
            {{item.category || 'Unnamed category'}}
          </ng-template>
        </ng-select><br/>

        <div class="text-center">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" value="l" id="l" [(ngModel)]="bold" (change)="onChange()">
            <label class="form-check-label" for="l">
              Fetter Text
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" value="n" id="n" [(ngModel)]="underlined" (change)="onChange()">
            <label class="form-check-label" for="n">
              Unterstrichender Text
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" value="m" id="m" [(ngModel)]="crossed" (change)="onChange()">
            <label class="form-check-label" for="m">
              Druchstrichender Text
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" value="o" id="o" [(ngModel)]="italic" (change)="onChange()">
            <label class="form-check-label" for="o">
              Kursive Text
            </label>
          </div>
        </div><br/>

        <textarea class="form-control" [(ngModel)]="text_input" (keyup)="onChange()"></textarea>
      </div>
    </div>

    <div class="card">
      <div class="card-body console">
        <h2 class="card-title">Text Vorschau</h2>
        <p [innerHTML]="preview_text"></p>
      </div>
    </div>

    <div class="alert alert-danger mt-4" role="alert" *ngIf="generated_text.length >= 256">
      <i class="fa-solid fa-exclamation-circle"></i> Dieser Text ist für den Minecraft Chat zu lang.
    </div>

    <div class="card">
      <div class="card-body console">
        <h2 class="card-title">Color Codes <button class="btn btn-primary" ngxClipboard [cbContent]="generated_text">Copy</button></h2>
        <p>{{generated_text}}</p>
      </div>
    </div>

    <div class="card" *ngIf="false">
      <div class="card-body">
        <h2 class="card-title">Debug</h2>
        <p>TextInput</p>
        <p>{{text_input || "-none-"}}</p>
        <p>Preview Text</p>
        <p>{{preview_text || "-none-"}}</p>
        <p>Generated Text</p>
        <p>{{generated_text || "-none-"}}</p>
      </div>
    </div>

  </div>

  <div class="col-md-12 card page-footer">
    <div class="card-body">
      <div class="row justify-content-center">
        <div class="col-md-8 row">
          <div class="col-md-6">
            <div class="footer-brand">
              <img src="./assets/images/winter-logo.gif" alt="Logo"/>
              <h2 class="footer-title"><b>NightDesign</b></h2>
            </div>
            <p class="text-muted">&copy; 2024 All rights reserved.</p>
            <p>Customize all of your Minecraft Cosmetics and share it to others.</p>
          </div>
          <div class="col-md-2 footer-links">
            <h3 class="text-muted">Help</h3>
            <p><a href="https://status.night.design">Status</a></p>
            <p><a href="https://wiki.night.design">Wiki</a></p>
            <p><a href="https://api.night.design">API Docs</a></p>
            <p><a href="https://status.night.design">Translate</a></p>
            <p><a href="https://night.design/discord">Discord</a></p>
          </div>
          <div class="col-md-2 footer-links">
            <h3 class="text-muted">Legal</h3>
            <p><a href="https://night.design/legal/imprint">Imprint</a></p>
            <p><a href="https://night.design/legal/privacy-policy">Privacy Policy</a></p>
            <p><a href="https://night.design/legal/terms-of-service">Terms of Service</a></p>
          </div>
          <div class="col-md-2 footer-links">
            <h3 class="text-muted">Partners</h3>
            <p><a href="https://halfhosting.de">HalfHosting</a></p>
            <p><a href="https://morecosmetics.net">MoreCosmetics</a></p>
          </div>

          <div class="col-md-6">
            <p class="text-muted">This work is licensed under CC BY-NC-SA 4.0</p>
          </div>
          <div class="col-md-6">
            <p class="text-muted float-md-end">Not affiliated with Mojang AB.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
