import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from "@sentry/angular-ivy";
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

Sentry.init({
  dsn: "https://388212cf11d444e78ab3f2ae0a8ff879@sentry.night.design/9",

  replaysSessionSampleRate: 0.8,
  replaysOnErrorSampleRate: 1.0,

  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/api\.night\.design/, /^https:\/\/api\.dev\.night\.design/],
  profilesSampleRate: 1.0,

  integrations: [
    Sentry.feedbackIntegration({
      colorScheme: "dark"
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.metrics.metricsAggregatorIntegration(),
  ]
});

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
