import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {ClipboardModule} from "ngx-clipboard";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, NgSelectModule, FormsModule, ClipboardModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  selectedTemplate: TemplateSettings = defaultTemplates[0];
  text_input: string = "Beispieltext";
  preview_text: string = "";
  generated_text: string = "";

  // special
  bold = "l";
  italic = false;
  underlined = false;
  crossed = false;

  ngOnInit(): void {
    this.onChange();
  }

  onChange() {
    const { html, code } = this.generateText(this.text_input);
    this.preview_text = html;
    this.generated_text = code;
  }

  generateText(text: String, template: TemplateSettings = this.selectedTemplate, ignore_specialFormatting: boolean = false): { html: string, code: string } {
    let html = "",
      code = "";

    // Special Formatting
    let specialFormattingHtml = "",
      specialFormattingCode = "";
    if (!ignore_specialFormatting) {
      if (this.bold) {
        specialFormattingHtml = " bold";
        specialFormattingCode = "&l";
      }
      if (this.italic) {
        specialFormattingHtml += " italic";
        specialFormattingCode += "&o";
      }
      if (this.underlined) {
        specialFormattingHtml += " underlined";
        specialFormattingCode += "&n";
      }
      if (this.crossed) {
        specialFormattingHtml += " line-through";
        specialFormattingCode += "&m";
      }
    }

    const reg = new RegExp(`.{1,${template.n !== 0 ? template.n : 2}}`,"g")
    const chunks = template.n === 0 ? [text] : text.match(reg) ?? [];
    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];
      const color = template.colors[i % template.colors.length];

      html += `<span class="preview ${color}${specialFormattingHtml}">${chunk}</span>`;
      code += `&${colorPlate[color].code}${specialFormattingCode}${chunk}`;
    }

    return { html, code };
  }

  protected readonly defaultTemplates = defaultTemplates;
}

export type Colors = "black" | "dark_blue" | "dark_green" | "dark_aqua" | "dark_red" | "dark_purple" | "gold" | "gray" | "dark_gray" | "blue" | "green" | "aqua" | "red" | "light_purple" | "yellow" | "white";
export class Color {
  code: string;
  color: string;
  shadow: string;

  constructor(code: string, color: string, shadow: string) {
    this.code = code;
    this.color = color;
    this.shadow = shadow;
  }
}
export const colorPlate: Record<Colors, Color> = {
  'black': { code: '0', color: '#000000', shadow: '#000000' },
  'dark_blue': { code: '1', color: '#0000AA', shadow: '#00002A' },
  'dark_green': { code: '2', color: '#00AA00', shadow: '#002A00' },
  'dark_aqua': { code: '3', color: '#00AAAA', shadow: '#002A2A' },
  'dark_red': { code: '4', color: '#AA0000', shadow: '#2A0000' },
  'dark_purple': { code: '5', color: '#AA00AA', shadow: '#2A002A' },
  'gold': { code: '6', color: '#FFAA00', shadow: '#2A2A00' },
  'gray': { code: '7', color: '#AAAAAA', shadow: '#2A2A2A' },
  'dark_gray': { code: '8', color: '#555555', shadow: '#151515' },
  'blue': { code: '9', color: '#5555FF', shadow: '#15153F' },
  'green': { code: 'a', color: '#55FF55', shadow: '#153F15' },
  'aqua': { code: 'b', color: '#55FFFF', shadow: '#153F3F' },
  'red': { code: 'c', color: '#FF5555', shadow: '#3F1515' },
  'light_purple': { code: 'd', color: '#FF55FF', shadow: '#3F153F' },
  'yellow': { code: 'e', color: '#FFFF55', shadow: '#3F3F15' },
  'white': { code: 'f', color: '#FFFFFF', shadow: '#3F3F3F' },
}

export class TemplateSettings {
  name: string;
  category: string = "";
  n: number ;
  colors: Colors[];

  constructor(name: string, n: number, colors: Colors[]) {
    this.name = name;
    this.n = n;
    this.colors = colors;
  }

  setCategory(value: string): this {
    this.category = value;
    return this;
  }
}
export const defaultTemplates: TemplateSettings[] = [
  // Einfarbig
  new TemplateSettings("Weiße Farbe",0, ["white"]).setCategory("Einfarbige Farben"),
  new TemplateSettings("Rote Farbe",0, ["red"]).setCategory("Einfarbige Farben"),
  new TemplateSettings("Goldene Farbe",0, ["gold"]).setCategory("Einfarbige Farben"),
  new TemplateSettings("Blaue Farbe",0, ["aqua"]).setCategory("Einfarbige Farben"),
  new TemplateSettings("Grüne Farbe",0, ["green"]).setCategory("Einfarbige Farben"),

  // Mischung mit Weiß
  new TemplateSettings("Weißgraue Farbe",2, ["white","gray"]).setCategory("Weiß Mischungen"),
  new TemplateSettings("RotWeiße Farbe",2, ["red","white"]).setCategory("Weiß Mischungen"),
  new TemplateSettings("Pinkweiße Farbe",2, ["light_purple","white"]).setCategory("Weiß Mischungen"),
  new TemplateSettings("Grünweiße Farbe",2, ["dark_green","white"]).setCategory("Weiß Mischungen"),
  new TemplateSettings("Viollettweiße Farbe",2, ["dark_purple","white"]).setCategory("Weiß Mischungen"),

  // Doppelte Farben
  new TemplateSettings("Doppelblaue Farbe",2, ["dark_aqua","aqua"]).setCategory("Doppel Farben"),
  new TemplateSettings("Doppelgelber Farbe",2, ["yellow","gold"]).setCategory("Doppel Farben"),
  new TemplateSettings("Doppellilane Farbe",2, ["dark_purple","light_purple"]).setCategory("Doppel Farben"),
  new TemplateSettings("Doppelrote Farbe",2, ["dark_red","red"]).setCategory("Doppel Farben"),
  new TemplateSettings("Doppelgraue Farbe",2, ["dark_gray","gray"]).setCategory("Doppel Farben"),
  new TemplateSettings("Doppelgrüne Farbe",2, ["green","dark_green"]).setCategory("Doppel Farben"),

  // Events
  new TemplateSettings("Halloween Farbe v1 (2020)",1, ["gold","gold","yellow","yellow","gray","dark_gray","gray","yellow","yellow"]).setCategory("Event Farben"),
  new TemplateSettings("Halloween Farbe v2 (2021)",1, ["dark_gray","dark_gray","gray","gray","yellow","gold","yellow","gray","gray"]).setCategory("Event Farben"),
  new TemplateSettings("Weihnachts Farbe v1 (2020)",2, ["dark_green","dark_red"]).setCategory("Event Farben"),
  new TemplateSettings("Weihnachts Farbe v2 (2022)",2, ["dark_red","dark_green"]).setCategory("Event Farben"),

  // EM2024
  new TemplateSettings("Deutschland Farbe (EM 2024)",1, ["black","dark_red","gold","white"]).setCategory("EM24 Farben"),
  new TemplateSettings("Österreich Farbe (EM 2024)",2, ["dark_red","red","white"]).setCategory("EM24 Farben"),
  new TemplateSettings("Schweiz Farbe (EM 2024)",2, ["white","red","dark_red"]).setCategory("EM24 Farben"),

  // Jahreszeiten
  new TemplateSettings("Frühlings Farbe",2, ["white","green","dark_green"]).setCategory("Jahreszeit Farben"),
  new TemplateSettings("Sommer Farbe",1, ["dark_aqua","aqua","yellow","gold","yellow"]).setCategory("Jahreszeit Farben"),
  new TemplateSettings("Herbst Farbe",1, ["dark_red","red","gold","yellow"]).setCategory("Jahreszeit Farben"),
  new TemplateSettings("Freundliche Herbst Farbe",2, ["gold","red","yellow"]).setCategory("Jahreszeit Farben"),
  new TemplateSettings("Frost Farbe",2, ["aqua","white","dark_aqua"]).setCategory("Jahreszeit Farben"),
  new TemplateSettings("Winter Farbe",2, ["aqua","white"]).setCategory("Jahreszeit Farben"),

  // Special
  new TemplateSettings("Deutschland Farbe",2, ["black","dark_red","gold"]).setCategory("Special Farben"),
  new TemplateSettings("Rainbow Farbe",1, ["red","gold","yellow","green","aqua","light_purple"]).setCategory("Special Farben"),
  new TemplateSettings("Lucky Farbe",2, ["gold","yellow","white"]).setCategory("Special Farben"),
  new TemplateSettings("Früchte Farbe",1, ["dark_green","green","yellow","gold","red"]).setCategory("Special Farben"),
  new TemplateSettings("Zuckerstangen Farbe",2, ["red","white"]).setCategory("Special Farben"),
  new TemplateSettings("Ocean Farbe",2, ["dark_blue","blue","aqua"]).setCategory("Special Farben"),
  new TemplateSettings("Galaxy Farbe",2, ["dark_purple","light_purple","aqua","dark_aqua"]).setCategory("Special Farben"),
  new TemplateSettings("3D-Farbe",1, ["dark_aqua","aqua","red","dark_red"]).setCategory("Special Farben"),
  new TemplateSettings("Inferno Farbe",2, ["dark_gray","gray","red","dark_red"]).setCategory("Special Farben"),
  new TemplateSettings("Oster Farbe",2, ["aqua","green","yellow","red"]).setCategory("Special Farben"),
  new TemplateSettings("Gelbgrüne Farbe",2, ["yellow","green"]).setCategory("Special Farben"),
  new TemplateSettings("Flower Farbe",1, ["aqua","green","yellow","gold","light_purple","dark_purple"]).setCategory("Special Farben"),
  new TemplateSettings("Orb Farbe",2, ["dark_gray","gray","aqua","dark_aqua"]).setCategory("Special Farben"),

  // Vorschläge
  new TemplateSettings("Gelb-Weiße Farbe",2, ["yellow","white"]).setCategory("Ideen Vorschläge"),
  new TemplateSettings("Blumen Farbe",2, ["dark_purple","light_purple","red"]).setCategory("Ideen Vorschläge"),
  new TemplateSettings("Feuer Farbe",2, ["white","gold","red"]).setCategory("Ideen Vorschläge"),
  new TemplateSettings("RotWeiße Farbe Lang",3, ["red","white"]).setCategory("Ideen Vorschläge"),
];
